<template>
  <v-card class="ma-4" flat>
    <v-row :class="darkmode || 'white'">
      <v-col cols="12" sm="9">
        <v-tabs v-model="tab" center-active @change="tabValue">
          <v-tab>
            {{ $t("absent.absent_per_day") }}
          </v-tab>
          <v-tab>
            {{ $t("absent.absent_per_subjects") }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <Recap
          :name="$t('recap_absent.student')"
          :searchData="search"
          :showRoleType="false"
          prefix="student"
        />
      </v-tab-item>
      <v-tab-item>
        <Header
          :idClass="idClass"
          :idGrade="idGrade"
          :idSchoolYear="idSchoolYear"
          :itemTeacher="itemTeacher"
          class="mt-5"
          @setIdClass="newValue => (idClass = newValue)"
          @setIdGrade="newValue => (idGrade = newValue)"
          @setIdSchoolYear="newValue => (idSchoolYear = newValue)"
          @setTeacher="newValue => (itemTeacher = newValue)"
        />
        <Table
          :idClass="idClass"
          :idGrade="idGrade"
          :idSchoolYear="idSchoolYear"
          :isInitData="initData"
          :itemTeacher="itemTeacher"
          @changeInitData="newValue => (initData = newValue)"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
  components: {
    Recap: () => import("./Recap"),
    Header: () => import("./components/Header"),
    Table: () => import("./components/Table")
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  data() {
    return {
      search: "",
      tab: this.$route.query.tab | 0,
      itemTeacher: [],
      initData: false,
      idClass: null,
      idGrade: null,
      idSchoolYear: null
    };
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "RecapAbsent", query: { tab: tab } });
    }
  }
};
</script>
